import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import PreviewComponent from '../app-components/PreviewComponent';

export default function Popover_Sample() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const samplePopover = 
  "<Popover className='popover'>" +
  "\n <h3>Personal Loan</h3>" +
  "\n <p>Access your loan information.</p>" +
  "\n <Button className='cancel'>Cancel</Button>" +
  "\n <Button className='primary'>Confirm</Button>" +
  "\n</Popover>";

  return (
    <div>
      <PreviewComponent title="" description="" code={samplePopover}>
        <Button onClick={handleClick}>Open Popover</Button>
        <Popover className='popover' open={open} anchorEl={anchorEl} onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <h3>Personal Loan</h3>
            <p>Access your loan information.</p>
            <Button className="cancel margin-right-base">Cancel</Button>
            <Button className="primary">Confirm</Button>
        </Popover>
      </PreviewComponent>
    </div>   
  );
}