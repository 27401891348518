import * as React from 'react';
import Link from '@mui/material/Link';
import PreviewComponent from '../app-components/PreviewComponent';


export default function Link_Sample() {

  const sampleLink = "<Link>Link - Default</Link>" +
    "\n<Link className='underlined-hover'>Link - Underlined on Hover</link>" +
    "\n<Link disabled>Link - Disabled</link>";
  

  return (
    <div>
      <PreviewComponent title="Default" description="">
        <Link>Link - Default</Link>
      </PreviewComponent>

      <PreviewComponent title="Underlined on Hover" description="" >
        <Link className='underlined-hover'>Link - Underlined on Hover</Link>
      </PreviewComponent>
      
      
      <PreviewComponent title="Disabled" description="" code={sampleLink}>
        <Link disabled>Link - Disabled</Link>
      </PreviewComponent>
   </div>
  );
}