import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import PreviewComponent from '../app-components/PreviewComponent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';


export default function BasicAlerts() {

const sample_basicAlerts= 
                        "<Alert className='error'>" +
                        "\n    <ErrorOutlineIcon/>" + 
                        "\n      This is an error alert" +
                        "\n</Alert>" +
                        "\n" +
                        "<Alert className='info'>" +
                        "\n    <InfoOutlinedIcon/>" + 
                        "\n      This is an info alert" +
                        "\n</Alert>" +
                        "\n" +
                        "<Alert className='success'>" +
                        "\n    <CheckCircleOutlinedIcon/>" + 
                        "\n      This is a success alert" +
                        "\n</Alert>" +
                        "\n" +
                        "<Alert className='warning'>" +
                        "\n    <WarningAmberOutlinedIcon/>" + 
                        "\n      This is warning alert" +
                        "\n</Alert>"

const sample_description=
                         "<Alert className='description info'>" +
                        "\n  <div>" +
                        "\n    <InfoOutlinedIcon/>" +
                        "\n  </div>" +
                        "\n  <div>" +
                        "\n   <AlertTitle>Info</AlertTitle>" +
                        "\n    This is an info alert!" +
                        "\n  </div>" +
                        "\n</Alert>"

  return (
    <div>
        <PreviewComponent title="Basic" description="" code={sample_basicAlerts}>
            <Stack sx={{width: 1/2}} direction='column' spacing={3}>
                <Alert className="error">
                    <ErrorOutlineIcon />
                        This is an error alert
                    </Alert>

                <Alert className="info">
                   <InfoOutlinedIcon />
                        This is an info alert
                  </Alert>

                <Alert className="success">
                    <CheckCircleOutlinedIcon />
                        This is a success alert
               </Alert>

                <Alert className="warning">
                    <WarningAmberOutlinedIcon />
                        This is a warning alert
               </Alert>
            </Stack>
        </PreviewComponent>

        <PreviewComponent title="Description" description="" code={sample_description}>
            <Stack sx={{width: 1/2}}>
                <Alert className="description info">
                    <div>
                        <InfoOutlinedIcon/>
                    </div>
                    <div>
                        <AlertTitle>Info</AlertTitle>
                        This is an info alert!
                    </div>
                </Alert>
            </Stack>
        </PreviewComponent>
    </div>
  );
}