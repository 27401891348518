import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { FormControl, Stack, TextField, MenuItem, CardContent, Card, InputLabel } from '@mui/material';
import * as React from 'react';
import LayoutTopWrapper from '../app-components/LayoutTopWrapper';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function SimpleForm() {

  const area = [
    { 
      value: 'select area',
    },
    { 
      value: 'Accesories',
    },
    {
      value: 'Headphones',
    },
    {
      value: 'Laptops',
    },
    {
      value: 'Smartphones',
    },
    {
      value: 'Printers'
    },
    {
      value: 'Storage',
    },
    {
      value: 'Smartwatches',
    }
  ];

    return (
        <LayoutTopWrapper 
            title="Product Request" 
            halfSize={true} 
            breadcrumbs={[
            <Link key="home">Home</Link>,
            <Link key="products">Products</Link>,
            <span key="products-request">Product Request</span>
            ]}
        >
          <Card>
          <CardContent>
              <form>
                
                  <InputLabel htmlFor='requested-by' required>Product requested by</InputLabel>
                  <TextField id='requested-by' placeholder="Name" required  sx={{width: 300}}/>
                
               
                <InputLabel required htmlFor='Select area'>Area</InputLabel>
                  <TextField
                    id='selectArea'
                    select
                    placeholder="Select area"
                    defaultValue="select area"
                    required
                    sx={{width: 300}}
                  >
                    {area.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField> 
                <div className='date-picker'>
                  <InputLabel htmlFor='Requested date'>Requested date</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{width: 150}}/>
                  </LocalizationProvider>
                </div>
                  <InputLabel htmlFor='email'>Email</InputLabel>
                  <TextField placeholder="Email" sx={{width: 300}}/>
               
                  <InputLabel htmlFor='Internal comments'>Internal comments</InputLabel>
                <TextField className="input-multiline" id="outlined-multiline-static"
                  multiline
                  rows={4}
                  placeholder="Insert comments"/>
               
                <FormControl>
                  <Stack direction="row" spacing={2}>
                    <Button type='reset' className='cancel'>Cancel</Button>
                    <Button type='submit' className='primary'>Request</Button>
                  </Stack>
                </FormControl>
            </form>
            </CardContent>
        </Card>
      </LayoutTopWrapper>
    );

}