import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import PreviewComponent from '../app-components/PreviewComponent';

export default function Variants() {

  const sample_Skeleton="<Skeleton variant='text'>" +
                      "\n<Skeleton variant='circular'/>" +
                      "\n<Skeleton variant='rectangular'/>" +
                      "\n<Skeleton variant='rounded' />"

  return (

    <div>

    <PreviewComponent title="Variants" description="" code={sample_Skeleton}>
      <Stack spacing={3} sx={{width: 210}}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text"/>
        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="rectangular" height={60} />
        <Skeleton variant="rounded" height={60} />
      </Stack>
    </PreviewComponent>

    </div>
  );
}