import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PreviewComponent from '../app-components/PreviewComponent';

export default function TemporaryDrawer() {

  const sampleCode_Drawer="<Drawer>" +
                        "\n  {drawerContent(anchor)}" +
                        "\n</Drawer>"

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const drawerContent = (anchor) => (
    <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400, height: anchor === 'right' || anchor === 'left' ? 'auto' : 250 }}
          className="margin-base"    
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
        <div className='center'>
            <Avatar src="/images/profile.jpg"/>
            <p className='font-regular font-size-h3 margin-bottom-none margin-top-s'>
              Morwan Chatila
            </p>
            <p className='font-size-s horizontal-align margin-bottom-none'>
            <EmailIcon className='font-size-s'/>morwan@couplahouse.com
            </p>
            <p className='font-size-s horizontal-align margin-bottom-none'>
            <LocalPhoneIcon className='font-size-s'/>+1 604428 0611
            </p>
        </div>
    </Box>
  );

  return (
   <div>
      <PreviewComponent title="" description="" code={sampleCode_Drawer}>
        {['top', 'right', 'bottom', 'left',].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} className='primary margin-left-s'>{anchor} </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {drawerContent(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </PreviewComponent>
  </div>   
  );
}