import * as React from 'react';
import './../App.scss';
import './../theme/theme.scss';
import LayoutTopWrapperLSG from "../app-components/LayoutTopWrapperLSG";
import PreviewComponent from '../app-components/PreviewComponent';
import { Paper, Link } from '@mui/material';


const sampleInstallation = "npm install --sssssssssssssss";


export default function GetStarted() {
   
    
return (

    <LayoutTopWrapperLSG title="Get Started" halfSize={false} breadcrumbs={[
            <Link to="/">Home</Link>,
            <span>Get Started</span>
          ]}>

        <div className='get-started'>

            <Paper className='padding-base margin-bottom-xxl'>
                <h4>Overview</h4>
                <p>This Live Style Guide (LSG) is a live visual reference, based on React, and developed as an extension to the 
                    default MUI Library styles. 
                    With this guide, developers can understand in real-time the look and feel of all the elements available to create applications, when using this theme.
                    The goal is to take advantage of ready to use MUI Components, fully customizable with this LSG unique styling, but maintaining all MUI's capabilities.</p>
            </Paper>

            
            <PreviewComponent code={sampleInstallation}><h4>Installation</h4><p className='font-size-base font-bold'>npm</p><p>In your project directory run:</p></PreviewComponent> 
        
            <Paper className='padding-base margin-bottom-xxl'>
                <h4 className='margin-top-none'>Usage</h4> 
                <p>This LSG is divided into 3 main parts:</p>

                <ol>
                    <li>
                        <h6>Styles</h6> 
                        <p>You can find in here all the information about the visual elements to create your project and a comprehensive list
                        of classes and CSS variables to apply and get the desired style of an element, totally independent from MUI Library
                        code.</p>
                    </li>
                    <li>
                        <h6>Components</h6> 
                        <p>This LSG provides a list of the most important MUI compoenents, each one with its own UI related code block, 
                        ready to copy and use to add style to it. Please note that this code must be used together with MUI's logic and functionalities' own code.</p>
                    </li>
                    <li>
                        <h6>Screen Templates</h6> 
                        <p>A set of the most common templates is available to speed up the building process. 
                        Each screen uses MUI Library components and conveys the look and feel of the theme.</p>
                    </li>
                </ol>
            </Paper>
            
            <Paper className='padding-base margin-bottom-xxl'>
                <h4>System Requirements</h4> 
                <ul>
                    <li>MUI v5.13 or higher</li>
                    <li>Up-to-date browser support for modern web technologies</li>
                </ul>
            </Paper>
        </div>
    </LayoutTopWrapperLSG>
)
}