import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PreviewComponent from '../app-components/PreviewComponent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function BasicList() {

const sampleList         = " <List>" +
                        "\n   <ListItem>" +
                        "\n     <ListItemButton>" +
                        "\n       <ListItemIcon>" +
                        "\n         <InboxIcon />" +
                        "\n       </ListItemIcon>" +
                        "\n       <ListItemText primary='Inbox' />" +
                        "\n     </ListItemButton>" +
                        "\n   </ListItem>" +
                        "\n   <ListItem>" +
                        "\n     <ListItemButton selected>" +
                        "\n       <ListItemIcon>" +
                        "\n         <DraftsIcon />" +
                        "\n       </ListItemIcon>" +
                        "\n       <ListItemText primary='Drafts' />" +
                        "\n     </ListItemButton>" +
                        "\n   </ListItem>"+
                        "\n </List>" +
                        "\n <List>" +
                        "\n  <ListItem>" +
                        "\n    <ListItemButton>" +
                        "\n       <ListItemText primary='Trash' />" +
                        "\n     </ListItemButton>" +
                        "\n   </ListItem>" +
                        "\n   <ListItem>" +
                        "\n     <ListItemButton component='a' href='#simple-list'>" +
                        "\n       <ListItemText primary='Spam' />" +
                        "\n     </ListItemButton>" +
                        "\n   </ListItem>" +
                        "\n </List>";


  return (
    <div>
      <PreviewComponent title="" description="" code={sampleList}>
        <Box sx={{width:300, p:2}}>
            <List >
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="Inbox"/> <ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton selected>
                  <ListItemText primary="Drafts" /><ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="Trash" /><ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Spam" /><ArrowForwardIosIcon/>
                </ListItemButton>
              </ListItem>
            </List>
        </Box>
      </PreviewComponent>
    </div>
  );
}