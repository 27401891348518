import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, CardMedia } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PreviewComponent from '../app-components/PreviewComponent';


export default function BasicCard() {

  const sampleCode_basicCard="<Card className='padding-none'>" +
                            "\n  <CardContent>" +
                            "\n    <div>" +
                            "\n      Take control with a loan made for you. Access your loan information whenever you need it," +
                            "\n      we developed tools to give you freedom to focus on the most important things" +
                            "\n    </div>" +
                            "\n  </CardContent>" +
                            "\n</Card>;"

  const sampleCode_paddingCard= "<Card>" +
                              "\n  <CardContent>" +
                              "\n    <div>" +
                              "\n      Take control with a loan made for you. Access your loan information whenever you need it," +
                              "\n      we developed tools to give you freedom to focus on the most important things" +
                              "\n    </div>" +
                              "\n  </CardContent>" +
                              "\n</Card>"

const sampleCode_mediaCard ="<Card>" + 
                          "\n  <CardMedia" +
                          "\n    component='img'" +
                          "\n    image='/path/to/image'" +
                          "\n    title='title of image'" +
                          "\n    className='img-cover'" +
                          "\n  />" +
                          "\n  <CardContent className='padding-left-base'>" +
                          "\n    <h4>Morwan Chatila</h4>" +
                          "\n    <p className='horizontal-align'>" +
                          "\n      <EmailIcon className='font-size-s margin-right-xs'/>morwan@couplahouse.com" +
                          "\n    </p>" +
                          "\n    <p className='horizontal-align'>" +
                          "\n      <LocalPhoneIcon className='font-size-s margin-right-xs'/>+1 604428 0611" +
                          "\n    </p>" +
                          "\n  </CardContent>" +
                          "\n</Card>"


  return (
  <div>
    <PreviewComponent title="Without Padding" description="" code={sampleCode_basicCard}>
        <Box sx={{ width: 400 }}>
          <Card className='padding-none'>
            <CardContent >
              <div>
                Take control with a loan made for you. Access your loan information whenever you need it, 
                we developed tools to give you freedom to focus on the most important things.
              </div>
            </CardContent>
          </Card>
        </Box>
    </PreviewComponent>

    <PreviewComponent title="With Padding" description="" code={sampleCode_paddingCard}>
        <Box sx={{ width: 400 }}>
          <Card>
            <CardContent>
              <div>
                Take control with a loan made for you. Access your loan information whenever you need it, 
                we developed tools to give you freedom to focus on the most important things.
              </div>
            </CardContent>
          </Card>
        </Box>
    </PreviewComponent>

    <PreviewComponent title="Media Card" description="" code={sampleCode_mediaCard}>
        <Box sx={{ width: 400 }}>
          <Card>
            <CardMedia
                component='img'
                image="/images/profile.jpg"
                title="Profile Picture"
                className='img-cover'
              />
            <CardContent className='padding-left-base'>
              <h4>Morwan Chatila</h4>
              <p className='horizontal-align'>
                <EmailIcon className="font-size-s margin-right-xs"/>morwan@couplahouse.com
              </p>
              <p className='horizontal-align'>
                <LocalPhoneIcon className="font-size-s margin-right-xs"/>+1 604428 0611
              </p>
            </CardContent>
          </Card>
        </Box>
    </PreviewComponent>
  </div>
  );
}