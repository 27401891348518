import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import PreviewComponent from '../app-components/PreviewComponent';

export default function CircularIndeterminate() {

  const sample_Circular="<Box>" +
                     "\n  <CircularProgress />" +
                     "\n</Box>"

  const sample_Circular_Colored="<Box>" +
                     "\n  <CircularProgress className='background-neutral-8/>" +
                     "\n</Box>"
              
  const sample_Linear="<Box>" +
                     "\n  <LinearProgress />" +
                     "\n</Box>"

  const sample_Linear_Colored="<Box>" +
                          "\n  <LinearProgress className='background-success'/>" +
                          "\n</Box>"

  return (
    <div>
        <PreviewComponent title="Circular" description="" code={sample_Circular}>
            <CircularProgress />
        </PreviewComponent>

        <PreviewComponent title="Circular with Color" description="" code={sample_Circular_Colored}>
            <CircularProgress className='background-neutral-8'/>
        </PreviewComponent>
            
        <PreviewComponent title="Linear" description="" code={sample_Linear}>
            <LinearProgress />
        </PreviewComponent>

        <PreviewComponent title="Linear with Color" description="" code={sample_Linear_Colored}>
            <LinearProgress className="background-success"/>
        </PreviewComponent>  
    </div>
  );
}