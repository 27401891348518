import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function LayoutTop_Sample() {

  const sampleCode_layoutTop = 
    '<div className={"layout-top" + (props.halfSize ? " layout-top--half-width" : "") + (menuVisible ? " app-menu-visible" : "")} key="layout-top">'+
    '\n\t<header className="layout-top__header">'+
    '\n\t\t<div className="layout-top__header-container layout-grid-container">'+
    '\n\t\t\t<div className="layout-top__header-application-name">'+
    '\n\t\t\t\t<img src={props.appLogoURL} alt="" />'+
    '\n\t\t\t\t{props.appName}'+
    '\n\t\t\t</div>'+
    '\n\t\t\t<nav className="layout-top__header-menu">'+
    '\n\t\t\t\t<AppMenu pages={props.appMenuPages}/>'+
                    '\n\t\t\t</nav>'+
                    '\n\t\t\t<div className="layout-top__header-actions">'+
                        '\n\t\t\t\t{props.headerActions}'+
                    '\n\t\t\t</div>'+
                    '\n\t\t\t<div className="hamburguer-menu" onClick={toggleMenu}></div>'+
                '\n\t\t</div>'+
    '\n\t</header>'+
    '\n</div>';
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutTop}>
        <h5>Properties:</h5>
        <ul>
          <li>halfSize: defines the content area to have half its regular width;</li>
          <li>title: defines the page title;</li>
        </ul>
      </PreviewComponent>
    </div>
  );
}