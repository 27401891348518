import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import PreviewComponent from '../../app-components/PreviewComponent';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

export default function MultipleSelectCheckmarks() {

  const sampleCode_checkmarks = 
                              "\n<FormControl>" +
                              "\n    <InputLabel >Tag</InputLabel>" +
                              "\n    <Select" +
                              "\n      multiple" +
                              "\n      input={<OutlinedInput label='Tag' />}" +
                              "\n    >" +
                              "\n    {names.map((name) => (" +
                              "\n      <MenuItem key={name} value={name}>" +
                              "\n        <Checkbox checked={personName.indexOf(name) > -1} />" +
                              "\n        <ListItemText primary={name} />" +
                              "\n      </MenuItem>" +
                              "\n    ))}" +
                              "\n    </Select>" +
                              "\n</FormControl>"


  const [personName, setPersonName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
       <PreviewComponent title="Checkmarks" description="" code={sampleCode_checkmarks}>
          <Box className="width-300">
            <FormControl className='full-width'>
              <InputLabel >Tag</InputLabel>
              <Select
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </PreviewComponent>
    </div>
  );
}