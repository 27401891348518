import * as React from 'react';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PreviewComponent from '../app-components/PreviewComponent';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const sampleTypes = "<Checkbox defaultChecked />" + 
  "\n<Checkbox />" +
  "\n<Checkbox disabled />" + 
  "\n<Checkbox disabled checked />" +
  "\n<Checkbox className='error' />";

const sampleWithLabel = "<FormGroup>" + 
"\n\u0020<FormControlLabel control={<Checkbox defaultChecked />} label='Label' />" +
"\n\u0020<FormControlLabel required control={<Checkbox />} label='Required' />" + 
"\n\u0020<FormControlLabel disabled control={<Checkbox />} label='Disabled' />" +
"\n</FormGroup>";

export default function Checkbox_Sample() {
  return (
    <div>
      <PreviewComponent title="Types" description="" code={sampleTypes}>
          <Stack spacing={2} direction="row">
              <Checkbox {...label} defaultChecked />
              <Checkbox {...label} />
              <Checkbox {...label} disabled />
              <Checkbox {...label} disabled checked />
              <Checkbox {...label} className="error" />
          </Stack>
      </PreviewComponent>

      <PreviewComponent title="With Label" description="" code={sampleWithLabel}>
          <Stack spacing={4} direction="column">
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
              <FormControlLabel required control={<Checkbox />} label="Required" />
              <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
            </FormGroup>
          </Stack>
      </PreviewComponent>   
    </div>
  );
}