import * as React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import NavigationIcon from '@mui/icons-material/Navigation';
import PreviewComponent from '../app-components/PreviewComponent';

export default function FloatingActionButton() {

  const sampleDefault = "<Fab>" +
                    "\n <AddIcon/>" +
                    "\n</Fab>";

  const sampleIcon = "<Fab className='icon'>" +
                  "\n <EditIcon/>" +
                  "\n</Fab>";

  const sampleExtended = "<Fab className='extended'>" +
                      "\n <NavigationIcon/>Navigate" +
                      "\n</Fab>";
  return (

    <div>
        <PreviewComponent title="Default" description="" code={sampleDefault}>
            <Fab>
              <AddIcon />
            </Fab>
        </PreviewComponent>
      
        <PreviewComponent title="With Icon" description="" code={sampleIcon}>
            <Fab className="icon">
              <EditIcon />
            </Fab>
        </PreviewComponent>
        
        <PreviewComponent title="Extended" description="" code={sampleExtended}>   
            <Fab className="extended">
              <NavigationIcon/>
              Navigate
            </Fab>
        </PreviewComponent>        
    </div>
  );
}