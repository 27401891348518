import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function LayoutLogin_Sample() {

  
  const sampleCode_layoutLogin = "";
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutLogin}></PreviewComponent>
    </div>
  );
}