import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PreviewComponent from '../app-components/PreviewComponent';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList() {

  const sample_transferList="<Grid className='container-content-flex'>" +
                          "\n  <Grid item>{customList(left)}</Grid>" +
                          "\n  <Grid item>" +
                          "\n    <Grid className='container-wrapper-flex'>" +
                          "\n      <Button" +
                          "\n        disabled={left.length === 0} >" +
                          "\n        <KeyboardDoubleArrowRightIcon/>" +
                          "\n      </Button>" +
                          "\n      <Button" +
                          "\n        disabled={leftChecked.length === 0} >" +
                          "\n        <KeyboardArrowRightIcon/>" +
                          "\n      </Button>" +
                          "\n      <Button" +
                          "\n        disabled={rightChecked.length === 0} >" +
                          "\n        <KeyboardArrowLeftIcon/>" +
                          "\n      </Button>" +
                          "\n      <Button" +
                          "\n        disabled={right.length === 0} >" +
                          "\n        <KeyboardDoubleArrowLeftIcon/>" +
                          "\n      </Button>" +
                          "\n    </Grid>" +
                          "\n    </Grid>" +
                          "\n  <Grid item>{customList(right)}</Grid>" +
                          "\n</Grid>";

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([0, 1, 2, 3]);
  const [right, setRight] = React.useState([4, 5, 6, 7]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    
    <Paper sx={{height: 230}} className='transfer-list-container'>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`List item ${value + 1}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <div>
        <PreviewComponent title="" description="" code={sample_transferList}>
          <Grid className='container-content-flex'>
            <Grid item>{customList(left)}</Grid>
            <Grid item>
              <Grid className='container-wrapper-flex'>
                <Button
                  onClick={handleAllRight}
                  disabled={left.length === 0} >
                  <KeyboardDoubleArrowRightIcon/>
                </Button>
                <Button
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0} >
                  <KeyboardArrowRightIcon/>
                </Button>
                <Button
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0} >
                  <KeyboardArrowLeftIcon/>
                </Button>
                <Button
                  onClick={handleAllLeft}
                  disabled={right.length === 0} >
                  <KeyboardDoubleArrowLeftIcon/>
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList(right)}</Grid>
        </Grid>
      </PreviewComponent>
    </div>  
  );
}