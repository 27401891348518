import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import PreviewComponent from '../../app-components/PreviewComponent';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];


export default function MultipleSelectChip() {

    const sampleCode_chip =
                          "<FormControl>" +
                          "\n   <InputLabel>Chip</InputLabel>" +
                          "\n   <Select" +
                          "\n     multiple" +
                          "\n     input={<OutlinedInput label='Chip'/>}" +
                          "\n     renderValue={(selected) => (" +
                          "\n       <Box>" +
                          "\n         {selected.map((value) => (" +
                          "\n           <Chip key={value} label={value} />" +
                          "\n         ))}" +
                          "\n       </Box>" + 
                          "\n      )}" +
                          "\n    >" +
                          "\n     {names.map((name) => (" +
                          "\n      <MenuItem" +
                          "\n       key={name}" +
                          "\n       value={name}" +
                          "\n      >" +
                          "\n       {name}" +
                          "\n      </MenuItem>" +
                          "\n      ))}" +
                          "\n    </Select>" +
                          "\n</FormControl>"


  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  
  return (
    <div>
      <PreviewComponent title="Chip" description="" code={sampleCode_chip}>
          <Box className='width-300'>
            <FormControl className='full-width'>
              <InputLabel>Chip</InputLabel>
              <Select
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Chip"/>}
                renderValue={(selected) => (
                  <Box>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
      </PreviewComponent>  
    </div>
  );
}