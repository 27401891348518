import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import PreviewComponent from '../app-components/PreviewComponent';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  const sampleCode_bottomNaviagtion="<BottomNavigation>" +
                                    "\n  <BottomNavigationAction label='Dashboard' icon={<InsertChartOutlinedTwoToneIcon />} />" +
                                    "\n  <BottomNavigationAction label='Tasks' icon={<FormatListBulletedOutlinedIcon />} />" +
                                    "\n  <BottomNavigationAction label='Projects' icon={<FolderOpenOutlinedIcon />} />" +
                                    "\n  <BottomNavigationAction label='Search' icon={<SearchOutlinedIcon />} />" +
                                    "\n</BottomNavigation>"

  return (
    <div >
      <PreviewComponent title="" description="" code={sampleCode_bottomNaviagtion}>
        <div class="frame-component">
            <BottomNavigation            
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction label="Dashboard" icon={<InsertChartOutlinedTwoToneIcon />} />
              <BottomNavigationAction label="Tasks" icon={<FormatListBulletedOutlinedIcon />} />
              <BottomNavigationAction label="Projects" icon={<FolderOpenOutlinedIcon />} />
              <BottomNavigationAction label="Search" icon={<SearchOutlinedIcon />} />
            </BottomNavigation>
        </div>
      </PreviewComponent>
    </div>
  );
}