import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Avatar } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import PreviewComponent from '../app-components/PreviewComponent';

export default function Chip_Sample() {

  const sampleColors = "<Chip label='Primary' className='background-primary'/>" +
                        "\n<Chip label='Secondary' className='background-secondary'/>" +
                        "\n<Chip label='Error' className='background-error'/>" +
                        "\n<Chip label='Info' className='background-info'/>" +
                        "\n<Chip label='Success' className='background-success'/>" +
                        "\n<Chip label='Warning' className='background-warning'/>";
                  

  const sampleColorsLight = "<Chip label='Primary' className='background-primary-light'/>" +
                          "\n<Chip label='Secondary' className='background-secondary-light'/>" +
                          "\n<Chip label='Error' className='background-error-light'/>" +
                          "\n<Chip label='Info' className='background-info-light'/>" +
                          "\n<Chip label='Success' className='background-success-light'/>" +
                          "\n<Chip label='Warning' className='background-warning-light'/>";

  const sampleNeutral = "<Chip label='Neutral 10' className='background-neutral-10'/>" +
                        "\n<Chip label='Neutral 9' className='background-neutral-9'/>" +
                        "\n<Chip label='Neutral 8' className='background-neutral-8'/>" +
                        "\n<Chip label='Neutral 7' className='background-neutral-7'/>" +
                        "\n<Chip label='Neutral 6' className='background-neutral-6'/>" +
                        "\n<Chip label='Neutral 5' className='background-neutral-5'/>" +
                        "\n<Chip label='Neutral 4' className='background-neutral-4'/>" +
                        "\n<Chip label='Neutral 3' className='background-neutral-3'/>" +
                        "\n<Chip label='Neutral 2' className='background-neutral-2'/>" +
                        "\n<Chip label='Neutral 1' className='background-neutral-1'/>" +
                        "\n<Chip label='Neutral 0' className='background-neutral-0'/>";
                  

   const sampleNeutralLight = "<Chip label='Neutral 10' className='background-neutral-10-light'/>" +
                            "\n<Chip label='Neutral 9' className='background-neutral-9-light'/>" +
                            "\n<Chip label='Neutral 8' className='background-neutral-8-light'/>" +
                            "\n<Chip label='Neutral 7' className='background-neutral-7-light'/>" +
                            "\n<Chip label='Neutral 6' className='background-neutral-6-light'/>" +
                            "\n<Chip label='Neutral 5' className='background-neutral-5-light'/>" +
                            "\n<Chip label='Neutral 4' className='background-neutral-4-light'/>" +
                            "\n<Chip label='Neutral 3' className='background-neutral-3-light'/>" +
                            "\n<Chip label='Neutral 2' className='background-neutral-2-light'/>" +
                            "\n<Chip label='Neutral 1' className='background-neutral-1-light'/>" +
                            "\n<Chip label='Neutral 0' className='background-neutral-0-light'/>";

  const sampleSpecial = "<Chip className='background-primary-light' icon={<FaceIcon />} label='With Icon'/>" +
                      "\n<Chip className='background-neutral-6' avatar={<Avatar alt='profile' src='/path/to/image'/>} label='With Avatar'/>"

  const sampleSizes = "<Chip label='Large' className='background-primary large'/>" +
                      "\n<Chip label='Default' className='background-primary'/>" +
                      "\n<Chip label='Small' className='background-primary small'/>";
  
  const sampleDeletable = "<Chip label='Deletable' className='deletable'/>";

  const sample_ClickableDeletable="<Chip" +
                                "\n  label='Clickable Deletable'" +
                                "\n  className='deletable'" +
                                "\n/>"

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDelete = () => {
      console.info('You clicked the delete icon.');
  };
                    
  return (

    <div>
        <PreviewComponent title="Main and Semantic Colors" description="" code={sampleColors}>
            <Stack direction="row" spacing={2}>
                <Chip label="Primary" className="background-primary"/>
                <Chip label="Secondary" className="background-secondary"/>
                <Chip label="Error" className="background-error" />
                <Chip label="Info" className="background-info" />
                <Chip label="Success" className="background-success" />
                <Chip label="Warning" className="background-warning" />
            </Stack>
        </PreviewComponent>

        <PreviewComponent title="Main and Semantic Colors - Light" description="" code={sampleColorsLight}>
            <Stack direction="row" spacing={2}>
                <Chip label="Primary" className="background-primary-light"/>
                <Chip label="Secondary" className="background-secondary-light"/>
                <Chip label="Error" className="background-error-light" />
                <Chip label="Info" className="background-info-light" />
                <Chip label="Success" className="background-success-light" />
                <Chip label="Warning" className="background-warning-light" />
            </Stack>
        </PreviewComponent>

        <PreviewComponent title="Neutral Colors" description="" code={sampleNeutral}>
            <Stack direction="row" spacing={2}>
                <Chip label="Neutral 10" className="background-neutral-10"/>
                <Chip label="Neutral 9" className="background-neutral-9"/>
                <Chip label="Neutral 8" className="background-neutral-8"/>
                <Chip label="Neutral 7" className="background-neutral-7"/>
                <Chip label="Neutral 6" className="background-neutral-6"/>
                <Chip label="Neutral 5" className="background-neutral-5"/>
                <Chip label="Neutral 4" className="background-neutral-4"/>
                <Chip label="Neutral 3" className="background-neutral-3"/>
                <Chip label="Neutral 2" className="background-neutral-2"/>
                <Chip label="Neutral 1" className="background-neutral-1"/>
                <Chip label="Neutral 0" className="background-neutral-0"/>        
            </Stack>
        </PreviewComponent>

        <PreviewComponent title="Neutral Colors - Light" description="" code={sampleNeutralLight}>
            <Stack direction="row" spacing={2} className='background-neutral-4 padding-base'>
                <Chip label="Neutral 10" className="background-neutral-10-light"/>
                <Chip label="Neutral 9" className="background-neutral-9-light"/>
                <Chip label="Neutral 8" className="background-neutral-8-light"/>
                <Chip label="Neutral 7" className="background-neutral-7-light"/>
                <Chip label="Neutral 6" className="background-neutral-6-light"/>
                <Chip label="Neutral 5" className="background-neutral-5-light"/>
                <Chip label="Neutral 4" className="background-neutral-4-light"/>
                <Chip label="Neutral 3" className="background-neutral-3-light"/>
                <Chip label="Neutral 2" className="background-neutral-2-light"/>
                <Chip label="Neutral 1" className="background-neutral-1-light"/>
                <Chip label="Neutral 0" className="background-neutral-0-light"/>        
            </Stack>
        </PreviewComponent>

        <PreviewComponent title="Special" description="" code={sampleSpecial}>
            <Stack direction="row" spacing={2}>
                <Chip className='background-primary-light' icon={<FaceIcon />} label="With Icon"/>
                <Chip className='background-neutral-4' avatar={<Avatar alt="profile" src="/images/profile.jpg" />} label="With Avatar"/>
            </Stack>
        </PreviewComponent>

        <PreviewComponent title="Sizes" description="" code={sampleSizes}>
            <Stack direction="row" spacing={2}>
                <Chip label="Large" className="background-primary large" />
                <Chip label="Default" className="background-primary" />
                <Chip label="Small" className="background-primary small"/>                
            </Stack>
        </PreviewComponent>
        
        <PreviewComponent title="Deletable" description="" code={sampleDeletable}>
                <Chip label="Deletable" onDelete={handleDelete} className="deletable"/>
        </PreviewComponent>

        <PreviewComponent title="Clickable and Deletable" description="" code={sample_ClickableDeletable}>
                <Chip
                    label="Clickable Deletable"
                    onClick={handleClick}
                    onDelete={handleDelete}
                    className="deletable clickable"
                />
        </PreviewComponent>
    </div>
  );
}