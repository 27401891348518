import * as React from 'react';
import { Stack, Slider} from '@mui/material';
import PreviewComponent from '../app-components/PreviewComponent';


const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  }
]
  
export default function Slider_Sample() {

  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sampleHorizontal = "<Slider/>" + 
                           "\n<Slider disabled/>";

  const sampleVertical = "<Slider orientation='vertical'/>" + 
                         "\n<Slider orientation='vertical' disabled/>";

  const sampleSizes = "<Slider className='small'/>" + 
                      "\n<Slider/>"; 

  const sampleColor = "<Slider className='secondary'/>";

  const sampleDiscrete = "<Slider marks/>";

  const sampleRange = "<Slider value={true} marks={marks} valueLabelDisplay='on'/>";

                       
  return (

  <div>     
    <PreviewComponent title="Horizontal" description="" code={sampleHorizontal}>
        <Stack sx={{ width: 400 }} spacing={4} direction="column">     
          <Slider />
          <Slider disabled/>
        </Stack>
    </PreviewComponent>

    <PreviewComponent title="Vertical" description="" code={sampleVertical}>
        <Stack sx={{ height: 250}} direction="row" spacing={3}> 
            <Slider orientation="vertical"/>
            <Slider orientation="vertical" disabled/>
        </Stack>
    </PreviewComponent>

    <PreviewComponent title="Sizes" description="" code={sampleSizes}>
      <Stack sx={{ width: 400 }} spacing={4} direction="column">
            <Slider className="small"/>
            <Slider/>
      </Stack>
    </PreviewComponent>

    <PreviewComponent title="Color" description="" code={sampleColor}>
      <Stack sx={{ width: 400 }}>
          <Slider className="secondary"/> 
      </Stack>
    </PreviewComponent>

    <PreviewComponent title="Discrete" description="" code={sampleDiscrete}>
      <Stack sx={{ width: 400 }}>
          <Slider marks step={5}/>
      </Stack>
    </PreviewComponent>

    <PreviewComponent title="Range Slider with Marks and Labels" description="" code={sampleRange}>
      <Stack sx={{ width: 400, mt: 3 }}>
          <Slider value={value} onChange={handleChange} marks={marks} valueLabelDisplay="on"/>
      </Stack>
    </PreviewComponent>
  </div>
  );
}

