import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DeleteOutlineRounded, CloudDownloadOutlined } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'; 
import PreviewComponent from '../app-components/PreviewComponent';

export const Button_Sample= () => {

  const sampleSizes = "<Button className='small'>Small</Button>" + 
  "\n<Button>Medium</Button>" +
  "\n<Button className='large'>Large</Button>";

  const sampleTypes = "<Button className='primary'>Primary</Button>" + 
  "\n<Button>Secondary</Button>" +
  "\n<Button className='cancel'>Cancel</Button>" + 
  "\n<Button className='success'>Success</Button>" +
  "\n<Button className='error'>Error</Button>";

  const sampleWithIcon = "<Button className='primary' startIcon={<DeleteOutlineRounded/>}>Delete</Button>" +
                         "\n<Button endIcon={<CloudDownloadOutlined/>}>Download</Button>" +
                         "\n<Button className='primary'><KeyboardArrowLeftIcon/></Button>" +
                         "\n<Button><KeyboardArrowRightIcon/></Button>";
  

  return (
    <div>
      <PreviewComponent title="Types" description="" code={sampleTypes}>
          <Stack spacing={2} direction="column">
            <Stack spacing={2} direction="row">
              <Button className='primary'>Primary</Button>
              <Button>Secondary</Button>
              <Button className='cancel'>Cancel</Button>
              <Button className='success'>Success</Button>
              <Button className='error'>Error</Button>
            </Stack>
            <Stack spacing={2} direction="row">
              <Button className='primary' disabled>Primary</Button>
              <Button disabled>Secondary</Button>
              <Button className='cancel' disabled>Cancel</Button>
              <Button className='success' disabled>Success</Button>
              <Button className='error' disabled>Error</Button>
            </Stack>
          </Stack>
      </PreviewComponent>

      <PreviewComponent title="Sizes" description="" code={sampleSizes}>
          <Stack spacing={2} direction="row">
            <Button className='small'>Small</Button>
            <Button>Medium</Button>
            <Button className='large'>Large</Button>
          </Stack>
      </PreviewComponent>

      <PreviewComponent title="With Icon" description="" code={sampleWithIcon}>
          <Stack spacing={2} direction="row">
            <Button className='primary' startIcon={<DeleteOutlineRounded />}>Delete</Button>
            <Button endIcon={<CloudDownloadOutlined />}>Download</Button>
            <Button className='primary'><KeyboardArrowLeftIcon/></Button>
            <Button><KeyboardArrowRightIcon /></Button>
          </Stack>
      </PreviewComponent>
    </div>
  );
}
