import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PreviewComponent from '../app-components/PreviewComponent';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {

    const sampleCode_CustomizedSnackbars="<Snackbar>" +
                                       "\n  <Alert className='success'>" +
                                       "\n    <CheckCircleOutlinedIcon/>" +
                                       "\n    This is a success message!" +
                                       "\n  </Alert>" +
                                       "\n</Snackbar>"

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
  <div>
    <PreviewComponent title="Customization" description="" code={sampleCode_CustomizedSnackbars}>
      <Stack sx={{ width: 200 }}>
          <Button onClick={handleClick}>
              Open custom snackbar
          </Button>
          <Snackbar open={open} >
            <Alert className="success" onClose={handleClose}>
                    <CheckCircleOutlinedIcon />
                    This is a success message!
            </Alert>
          </Snackbar>
      </Stack>
    </PreviewComponent>
  </div>
  );
}