import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PreviewComponent from '../../app-components/PreviewComponent';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect() {

   const sampleCode_default=
                          "<FormControl>" +
                          "\n    <InputLabel>Name</InputLabel>" +
                          "\n    <Select" +
                          "\n      multiple" +
                          "\n      value={personName}" +
                          "\n      input={<OutlinedInput label='Name' />}" +
                          "\n    >" +
                          "\n    {names.map((name) => (" +
                          "\n      <MenuItem" +
                          "\n      key={name}" +
                          "\n      value={name}" +
                          "\n      >" +
                          "\n       {name}" +
                          "\n      </MenuItem>" +
                          "\n    ))}" +
                          "\n    </Select>" +
                          "\n  </FormControl>"


  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <PreviewComponent title="Default" description="" code={sampleCode_default}>
          <Box className='width-300'>
              <FormControl className='full-width'>
                <InputLabel>Name</InputLabel>
                <Select
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Box>
      </PreviewComponent>
    </div>
  );
}