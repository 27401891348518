import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomizedSnackbars from './SnackbarCustomization_Sample';
import { Stack } from '@mui/material';
import PreviewComponent from '../app-components/PreviewComponent';

export default function SimpleSnackbar() {

const sampleCode_SimpleSnackbar= "<Snackbar" +
                               "\n  message='Note archived'" +
                               "\n/>"

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
        <PreviewComponent title="Simple" description="" code={sampleCode_SimpleSnackbar}>
          <Stack sx={{ width: 200 }}>
            <Button onClick={handleClick}>Open simple snackbar</Button>
              <Snackbar
                open={open}
                message="Note archived"
                action={action}
              />
          </Stack>
        </PreviewComponent>      

        <CustomizedSnackbars/>
    </div>
  );
}