import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './theme/theme.scss';
import './App.scss';

//Live Style Guide Screens
import ScreenTemplates from './pages/ScreenTemplates';
import Components from './pages/Components';
import Styles from './pages/Styles';
import Home from './pages/Home';
import GetStarted from './pages/GetStarted';
import NotFound from './pages/NotFound';

//Screen Samples
import LoadingScreen from './screen-templates/LoadingScreen';
import SimpleForm from './screen-templates/SimpleForm';
import SimpleList from './screen-templates/SimpleList';
import Login from './screen-templates/Login';
import Error from './screen-templates/Error';


function App() {

  const theme = createTheme({
    typography: {
      fontFamily: [
        'SofiaPro-Regular', //TODO: Configure this font family for each project, if needed.
      ].join(','),
    },});

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="get-started" element={<GetStarted/>}/>
              <Route path="styles" element={<Styles/>}/>
              <Route path="components" element={<Components />}/>
              <Route path="screen-templates" element={<ScreenTemplates />}/>
              <Route path="loading-screen" element={<LoadingScreen />}/>
              <Route path="simple-form" element={<SimpleForm />}/>
              <Route path="simple-list" element={<SimpleList />}/>
              <Route path="login" element={<Login />}/>
              <Route path="error" element={<Error />}/>
              <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;