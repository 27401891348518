import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PreviewComponent from '../app-components/PreviewComponent';


export default function Menu_Sample() {

    const sampleMenu = "<Box className='menu'>" +
    "\n <Paper>" +
    "\n  <MenuList>" +
    "\n   <MenuItem>" +
    "\n    <ListItemIcon>" +
    "\n      <EditOutlinedIcon />" +
    "\n    </ListItemIcon>" +
    "\n    <ListItemText>Menu Item 1</ListItemText>" +
    "\n   </MenuItem>" +
    "\n   <MenuItem>" +
    "\n    <ListItemIcon>" +
    "\n      <EditOutlinedIcon />" +
    "\n    </ListItemIcon>" +
    "\n    <ListItemText>Menu Item 2</ListItemText>" +
    "\n   </MenuItem>" +
    "\n   <MenuItem>" +
    "\n    <ListItemIcon>" +
    "\n      <EditOutlinedIcon />" +
    "\n    </ListItemIcon>" +
    "\n    <ListItemText>Menu Item 3</ListItemText>" +
    "\n   </MenuItem>" +
    "\n   <MenuItem>" +
    "\n    <ListItemIcon>" +
    "\n      <EditOutlinedIcon />" +
    "\n    </ListItemIcon>" +
    "\n    <ListItemText>Menu Item 4</ListItemText>" +
    "\n   </MenuItem>" +
    "\n  </MenuList>" +
    "\n </Paper>" +
    "\n</Box>";


  return (
    <div>
      <PreviewComponent title="" description="" code={sampleMenu}>
        <Box className="menu">
          <Paper>
            <MenuList>
              <MenuItem>
                <ListItemIcon>
                    <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Menu Item 1</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                    <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Menu Item 2</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                    <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Menu Item 3</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                    <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Menu Item 4</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </Box>
      </PreviewComponent>
    </div>

  );
}