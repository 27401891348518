import LayoutTop from "../theme/custom-components/layout-top/LayoutTop";

export default function LayoutTopWrapper(props){

    const footer = "2023 \u00A9 Powered by Hi Interactive";
    const appLogoURL = "../images/logo-250.png";
    const appLogoFullURL = "../images/logo-full.svg";
    const appName = "Application name";
    const appMenuPages = [
        { id: 1, url: '/', label: 'Dashboard', icon:'SpeedOutlinedIcon'},
        { id: 2, url: '/', label: 'Users', icon:'PeopleAltOutlinedIcon'},
        { id: 3, url: '/', label: 'Account', icon:'PersonOutlineOutlinedIcon'},
        { id: 4, url: '/', label: 'Messages', icon:'ChatBubbleOutlineOutlinedIcon' }
      ];  
    const headerActions = "";

    function userInfo() {
        
            return (
                <div>
                    <div>John Smith Carvalho</div>
                    <div>johnsmithcarvalho@gmail.com</div>
                </div>
            )
        
    }

    return(

        <LayoutTop 
                halfSize={props.halfSize}    
                title={props.title}
                breadcrumbs={props.breadcrumbs}
                actions={props.actions}
                footer={footer}
                appLogoURL={appLogoURL}
                appLogoFullURL={appLogoFullURL}
                appName={appName}
                appMenuPages={appMenuPages}
                menuBottom={userInfo()}
                headerActions={headerActions}
                >    
            {props.children}
        </LayoutTop>
    )
}