import * as React from 'react';

import { Button, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LayoutLogin from '../theme/custom-components/layout-login/LayoutLogin';


export default function Login() {

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    
    //props for login layout
    const leftTitle = "Welcome";
    const leftDescription = "Custom theme to be used with React applications using MUI framework.";
    const leftBackgroundURL = "/images/HiResources.bg_login.jpg";
    const appLogoURL = "/images/HiResources.Logo_Full.svg";
    const appName = "Application Name";

    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };


    return (
        <LayoutLogin 
            leftTitle = {leftTitle}
            leftDescription = {leftDescription} 
            leftBackgroundURL = {leftBackgroundURL}
            appLogoURL = {appLogoURL}
            appName = {appName}
            >
            <form>
                <div>
                    <InputLabel htmlFor='username' required>Username</InputLabel>
                    <TextField placeholder="username" required id='username' className="full-width"/>
                    <InputLabel htmlFor='password' required>Password</InputLabel>
                    <Input
                        placeholder="password"
                        className='password'
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>}
                    />
                </div>
                <div className="margin-top-l">
                    <Button className='primary'>Log in</Button>
                </div>
            </form>
        </LayoutLogin>

    )
}