import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PreviewComponent from '../app-components/PreviewComponent';
import { Divider } from '@mui/material';

export default function AlertDialog() {

const sampleCode_Dialog ="<Dialog>" +
                        "\n  <DialogTitle>Personal Loan</DialogTitle>" +
                        "\n  <DialogContent>" +
                        "\n    <DialogContentText>" +
                        "\n      Take control with a loan made for you. Access your " +
                        "\n      loan information whenever you need it, " +
                        "\n      we developed tools to give you freedom to focus on " +
                        "\n      the most important things." +
                        "\n    </DialogContentText>" +
                        "\n  </DialogContent>" +
                        "\n  <Divider />" +
                        "\n  <DialogActions className='justify-content-left'>" +
                        "\n    <Button className='cancel'>Cancel</Button>" +
                        "\n    <Button className='primary'>Confirm</Button>" +
                        "\n  </DialogActions>" +
                        "\n</Dialog>"

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_Dialog}>
          <Button onClick={handleClickOpen}>Open alert dialog</Button>
          <Dialog  open={open}>
            <DialogTitle >Personal Loan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Take control with a loan made for you. Access your 
                loan information whenever you need it, 
                we developed tools to give you freedom to focus on 
                the most important things.
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className='justify-content-left'>
              <Button onClick={handleClose} className='cancel'>Cancel</Button>
              <Button className='primary'>Confirm</Button>
            </DialogActions>
          </Dialog>
      </PreviewComponent>
    </div>
  );
}

     
      