import * as React from 'react';
import { Pagination, Stack } from '@mui/material';
import PreviewComponent from '../app-components/PreviewComponent';

export default function Pagination_Sample() {

  const samplePagination = "<Pagination count{5}/>" +
  "\n<Pagination count{10}/>";


  return (
    <div>
        <PreviewComponent title="" description="" code={samplePagination}>
          <Stack spacing={4}>
              <Pagination count={5}/>
              <Pagination count={10}/>
          </Stack>
        </PreviewComponent>
    </div>
  );
}