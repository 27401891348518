import * as React from 'react';
import LayoutBlankWrapper from '../app-components/LayoutBlankWrapper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button } from '@mui/material';

export default function Error() {

    return (

        <LayoutBlankWrapper>

            <ErrorOutlineIcon className="margin-bottom-base" sx={{width:304, height:304, color:"var(--color-primary)"}}/>
            <div className='font-size-display margin-bottom-base'> Sorry,</div>
            <div className='font-size-s margin-bottom-l'> It seems that something is not right on this screen, you may not have access.</div>
            <div>
            <Button> Contact Technical Support</Button>
            <Button className='primary margin-left-base'>Go to homepage</Button>
            </div>

        </LayoutBlankWrapper>

    )

}