import * as React from 'react';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import PreviewComponent from '../app-components/PreviewComponent';
import { Stack } from '@mui/material';


export default function Divider_Sample() {


  const sampleDivider = "<Divider/>"


  const sample_dividerWithtext="<Divider className='text-align-left'>LEFT</Divider>" +
                              "\n<Divider>CENTER</Divider>" +
                              "\n<Divider className='text-align-right'>RIGHT</Divider>" +
                              "\n<Divider>" +
                              "\n   <Chip label='CHIP' className='background-primary' />" +
                              "\n</Divider>"

  const sample_dividerColors="<Divider className='background-info'></Divider>" +
                            "\n<Divider className='background-warning text-align-left'>LEFT</Divider>" +
                            "\n<Divider className='background-success text-success'>CENTER</Divider>" +
                            "\n<Divider className='background-neutral-9 text-primary text-align-right'>RIGHT</Divider>" +
                            "\n<Divider className='background-primary'>" +
                            "\n   <Chip label='CHIP' className='background-primary' />" +
                            "\n</Divider>"
                     

  return (
    <div>
      <PreviewComponent title="Default" description="" code={sampleDivider}>
          <Divider/>
      </PreviewComponent>

      <PreviewComponent title="With Other Content" subtitle="" description="" code={sample_dividerWithtext}>
        <Stack direction='column' spacing={3}>
          <Divider className='text-align-left'>LEFT</Divider>
          <Divider>CENTER</Divider>
          <Divider className='text-align-right'>RIGHT</Divider>
          <Divider>
            <Chip label="CHIP" className='background-primary' />
          </Divider>
        </Stack>
      </PreviewComponent> 

      <PreviewComponent title="With Colors" subtitle="" description="" code={sample_dividerColors}>
        <Stack direction='column' spacing={3}>
          <Divider className='background-info'></Divider>
          <Divider className='text-align-left background-warning'>LEFT</Divider>
          <Divider className='text-success background-success'>CENTER</Divider>
          <Divider className='text-align-right text-primary background-neutral-9'>RIGHT</Divider>
          <Divider className='background-primary'>
            <Chip label="CHIP" className='background-primary' />
          </Divider>
        </Stack>
      </PreviewComponent> 
    </div>
  );
}