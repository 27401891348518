import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PreviewComponent from '../app-components/PreviewComponent';
import { Box } from '@mui/material';

export default function SimpleAccordion() {

  const sampleCode_basicAccordion="<div>" + 
                                  "\n  <Accordion className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n      I noticed some erratic behavior from my screen" +
                                  "\n    </AccordionSummary>" +
                                  "\n    <AccordionDetails>" +
                                  "\n        Screens are great assets for anyone working in an office set up. They allow you to have more programs open," +
                                  "\n        multitask and be more productive." +
                                  "\n    </AccordionDetails>" +
                                  "\n  </Accordion>" +
                                  "\n  <Accordion className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n      Some of my keyboard keys are not working properly" +
                                  "\n    </AccordionSummary>" +
                                  "\n     <AccordionDetails>" +
                                  "\n        After a while, it’s common for keyboards to start malfunctioning. We have the habit of pressing the same keys" +
                                  "\n        which causes stress on their mechanics leading to problems." +
                                  "\n    </AccordionDetails>" +
                                  "\n  </Accordion>" +
                                  "\n  <Accordion className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n        I need a new mouse, the left-click is not working" +
                                  "\n    </AccordionSummary>" +
                                  "\n    <AccordionDetails>" +
                                  "\n        Mice are incredible accelerators that ease navigation and access. They easily become overused," +
                                  "\n        with all the clicking, dragging and scrolling, which means their life can be short." +
                                  "\n    </AccordionDetails>" +
                                  "\n  </Accordion>" + 
                                  "\n<div>";

  const sampleCode_controlledAccordion="<div>" + 
                                  "\n  <Accordion className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n      I noticed some erratic behavior from my screen" +
                                  "\n    </AccordionSummary>" +
                                  "\n    <AccordionDetails>" +
                                  "\n        Screens are great assets for anyone working in an office set up. They allow you to have more programs open," +
                                  "\n        multitask and be more productive." +
                                  "\n    </AccordionDetails>" +
                                  "\n  </Accordion>" +
                                  "\n   <Accordion className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n      Some of my keyboard keys are not working properly" +
                                  "\n    </AccordionSummary>" +
                                  "\n    <AccordionDetails>" +
                                  "\n        After a while, it’s common for keyboards to start malfunctioning. We have the habit of pressing the same keys" +
                                  "\n        which causes stress on their mechanics leading to problems." +
                                  "\n    </AccordionDetails>" +
                                  "\n  </Accordion>" +
                                  "\n  <Accordion className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n        I need a new mouse, the left-click is not working" +
                                  "\n    </AccordionSummary>" +
                                  "\n    <AccordionDetails>" +
                                  "\n        Mice are incredible accelerators that ease navigation and access. They easily become overused," +
                                  "\n        with all the clicking, dragging and scrolling, which means their life can be short." +
                                  "\n    </AccordionDetails>" +
                                  "\n  </Accordion>" +
                                  "\n<div>";

  const sampleCode_disabledAccordion="<div>" + 
                                  "\n  <Accordion disabled className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n      I noticed some erratic behavior from my screen" +
                                  "\n    </AccordionSummary>" +
                                  "\n  </Accordion>" +
                                  "\n  <Accordion disabled className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +  
                                  "\n      Some of my keyboard keys are not working properly" +
                                  "\n    </AccordionSummary>" +
                                  "\n  </Accordion>" +
                                  "\n  <Accordion expanded disabled className='accordion-item'>" +
                                  "\n    <AccordionSummary expandIcon={<ExpandMoreIcon />}>" +
                                  "\n        I need a new mouse, the left-click is not working" +
                                  "\n    </AccordionSummary>" +
                                  "\n    <AccordionDetails>" +
                                  "\n        Mice are incredible accelerators that ease navigation and access. They easily become overused," +
                                  "\n        with all the clicking, dragging and scrolling, which means their life can be short." +
                                  "\n    </AccordionDetails>" +
                                  "\n  </Accordion>" +
                                  "\n<div>";

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
  <div>  
    <PreviewComponent title="Basic" description="" code={sampleCode_basicAccordion}>
        <Box>
          <Accordion className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                I noticed some erratic behavior from my screen</AccordionSummary>
            <AccordionDetails>
              Screens are great assets for anyone working in an office set up. They allow you to have more programs open, 
              multitask and be more productive.
            </AccordionDetails>
          </Accordion>
          <Accordion className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Some of my keyboard keys are not working properly
            </AccordionSummary>
            <AccordionDetails>
              After a while, it’s common for keyboards to start malfunctioning. We have the habit of pressing the same keys 
              which causes stress on their mechanics leading to problems.
            </AccordionDetails>
          </Accordion>
          <Accordion className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              I need a new mouse, the left-click is not working
            </AccordionSummary>
            <AccordionDetails>
              Mice are incredible accelerators that ease navigation and access. They easily become overused, 
              with all the clicking, dragging and scrolling, which means their life can be short.
            </AccordionDetails>
          </Accordion>
        </Box>
    </PreviewComponent>

    <PreviewComponent title="Controlled" description="" code={sampleCode_controlledAccordion}>
        <Box>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
             I noticed some erratic behavior from my screen
            </AccordionSummary>
            <AccordionDetails>
              Screens are great assets for anyone working in an office set up. They allow you to have more programs open, 
              multitask and be more productive.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Some of my keyboard keys are not working properly
            </AccordionSummary>
            <AccordionDetails>
              After a while, it’s common for keyboards to start malfunctioning. We have the habit of pressing the same keys 
              which causes stress on their mechanics leading to problems.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              I need a new mouse, the left-click is not working
            </AccordionSummary>
            <AccordionDetails>
              Mice are incredible accelerators that ease navigation and access. They easily become overused, 
              with all the clicking, dragging and scrolling, which means their life can be short.
            </AccordionDetails>
          </Accordion>
        </Box>
    </PreviewComponent>

    <PreviewComponent title="Disabled" description="" code={sampleCode_disabledAccordion}>
        <Box>
          <Accordion disabled className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              I noticed some erratic behavior from my screen
            </AccordionSummary>
          </Accordion>
          <Accordion disabled className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Some of my keyboard keys are not working properly
            </AccordionSummary>
          </Accordion>
          <Accordion expanded disabled className='accordion-item'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              I need a new mouse, the left-click is not working
            </AccordionSummary>
            <AccordionDetails>
              Mice are incredible accelerators that ease navigation and access. They easily become overused, 
              with all the clicking, dragging and scrolling, which means their life can be short.
            </AccordionDetails>
          </Accordion>
        </Box>
    </PreviewComponent>
  </div>
  );
}