import AutoComplete_Sample from "../sample-components/AutoComplete_Sample/AutoComplete_Sample";
import { Button_Sample } from "../sample-components/Button_Sample";
import ButtonGroup_Sample from "../sample-components/ButtonGroup_Sample";
import ToggleButton_Sample from "../sample-components/ToggleButton_Sample";
import Checkbox_Sample from "../sample-components/Checkbox_Sample";
import Rating_Sample from "../sample-components/Rating_Sample";
import Table_Sample from "../sample-components/Table_Sample";
import TextField_Sample from "../sample-components/TextField_Sample";
import RadioGroup_Sample from "../sample-components/RadioGroup_Sample";
import Select_Sample from "../sample-components/Select_Sample/Select_Sample";
import Switch_Sample from "../sample-components/Switch_Sample";
import Slider_Sample from "../sample-components/Slider_Sample";
import FloatingActionButton_Sample from "../sample-components/FloatingActionButton_Sample";
import Avatar_Sample from "../sample-components/Avatar_Sample";
import Badge_Sample from "../sample-components/Badge_Sample";
import Chip_Sample from "../sample-components/Chip_Sample";
import Divider_Sample from "../sample-components/Divider_Sample";
import Icons_Sample from "../sample-components/Icons_Sample";
import List_Sample from "../sample-components/List_Sample";
import Tooltip_Sample from "../sample-components/Tooltip_Sample";
import Alert_Sample from "../sample-components/Alert_Sample";
import Backdrop_Sample from "../sample-components/Backdrop_Sample";
import Dialog_Sample from "../sample-components/Dialog_Sample";
import Progress_Sample from "../sample-components/Progress_Sample";
import Skeleton_Sample from "../sample-components/Skeleton_Sample";
import Snackbar_Sample from "../sample-components/Snackbar_Sample";
import Accordion_Sample from "../sample-components/Accordion_Sample";
import Card_Sample from "../sample-components/Card_Sample";
import Breadcrumbs_Sample from "../sample-components/Breadcrumbs_Sample";
import Link_Sample from "../sample-components/Link_Sample";
import Menu_Sample from "../sample-components/Menu_Sample";
import Modal_Sample from "../sample-components/Modal_Sample";
import Pagination_Sample from "../sample-components/Pagination_Sample";
import Popover_Sample from "../sample-components/Popover_Sample";
import Popper_Sample from "../sample-components/Popper_Sample";
import SpeedDial_Sample from "../sample-components/SpeedDial_Sample";
import Stepper_Sample from "../sample-components/Stepper_Sample";
import Tabs_Sample from "../sample-components/Tabs_Sample";
import BottomNavigation_Sample from "../sample-components/BottomNavigation_Sample";
import Drawer_Sample from "../sample-components/Drawer_Sample";
import TransferList_Sample from "../sample-components/TransferList_Sample";
import AppBar_Sample from "../sample-components/AppBar_Sample";
import Paper_Sample from "../sample-components/Paper_Sample";
import LayoutTop_Sample from "../sample-components/Layouts/LayoutTop_Sample";
import LayoutBlank_Sample from "../sample-components/Layouts/LayoutBlank_Sample";
import LayoutLogin_Sample from "../sample-components/Layouts/LayoutLogin_Sample";
import { Link } from "react-router-dom";
import LayoutTopWrapperLSG from "../app-components/LayoutTopWrapperLSG";
import { Divider, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";


export default function Components(){

    let componentList = [
        {
            id:0,
            name : "Inputs",
            components : [
                {
                    id:0,
                    name : "Auto Complete",
                    component : AutoComplete_Sample,
                    description: "The autocomplete is a normal text input enhanced by a panel of suggested options."
                },
                {   
                    id:1,
                    name : "Button",
                    component : Button_Sample,
                    description: "Buttons allow users to take actions, and make choices, with a single tap."
                },
                {
                    id:2,
                    name : "Button Group",
                    component : ButtonGroup_Sample,
                    description: "The ButtonGroup component can be used to group related buttons."
                },
                {
                    id:3,
                    name : "Checkbox",
                    component : Checkbox_Sample,
                    description: "Checkboxes allow the user to select one or more items from a set."
                },
                {
                    id:4,
                    name : "Floating Action Button",
                    component : FloatingActionButton_Sample,
                    description: "A Floating Action Button (FAB) performs the primary, or most common, action on a screen."
                },      
                {
                    id:5,
                    name : "Radio Group",
                    component : RadioGroup_Sample,
                    description: "The Radio Group allows the user to select one option from a set."
                },
                {
                    id:6,
                    name : "Rating",
                    component : Rating_Sample,
                    description : "Ratings provide insight regarding others' opinions and experiences, and can allow the user to submit a rating of their own.",
                },
                {
                    id:7,
                    name : "Select",
                    component : Select_Sample,
                    description: "Select components are used for collecting user provided information from a list of options."
                },
                {
                    id:8,
                    name : "Slider",
                    component : Slider_Sample,
                    description: "Sliders allow users to make selections from a range of values."
                },
                {
                    id:9,
                    name : "Switch",
                    component : Switch_Sample,
                    description: "Switches toggle the state of a single setting on or off."
                },
                {
                    id:10,
                    name : "Text Field",
                    component : TextField_Sample,
                    description: "Text Fields let users enter and edit text."
                },  
                {
                    id:11,
                    name : "Toggle Button",
                    component : ToggleButton_Sample,
                    description: "A Toggle Button can be used to group related options."
                },
                {
                    id:12,
                    name : "Transfer List",
                    component : TransferList_Sample,
                    description: "A Transfer List (or 'shuttle') enables the user to move one or more list items between lists."
                }
            ],
        },
        {
            id:1,
            name : "Data Display",
            components : [
                {
                    id:0,
                    name : "Avatar",
                    component : Avatar_Sample,
                    description: "Avatars are found throughout material design with uses in everything from tables to dialog menus."
                },
                {
                    id:1,
                    name : "Badge",
                    component : Badge_Sample,
                    description: "Badge generates a small badge to the top-right of its child(ren)."
                },
                {
                    id:2,
                    name : "Chip",
                    component : Chip_Sample,
                    description: "Chips are compact elements that represent an input, attribute, or action."
                },  
                {
                    id:3,
                    name : "Divider",
                    component : Divider_Sample,
                    description: "A divider is a thin line that groups content in lists and layouts."
                }, 
                {
                    id:4,
                    name : "Icons",
                    component : Icons_Sample
                }, 
                {
                    id:5,
                    name : "List",
                    component : List_Sample,
                    description: "Lists are continuous, vertical indexes of text or images."
                },   
                {
                    id:6,
                    name : "Table",
                    component : Table_Sample,
                    description: "Tables display sets of data. They can be fully customized."
                },   
                {
                    id:7,
                    name : "Tooltip",
                    component : Tooltip_Sample,
                    description: "Tooltips display informative text when users hover over, focus on, or tap an element."
                }
            ]
        },
        {
            id:2,
            name : "Feedback",
            components : [
                {
                    id:0,
                    name : "Alert",
                    component : Alert_Sample,
                    description: "An alert displays a short, important message in a way that attracts the user's attention without interrupting the user's task."
                },
                {
                    id:1,
                    name : "Backdrop",
                    component : Backdrop_Sample,
                    description: "The Backdrop component narrows the user's focus to a particular element on the screen."
                },
                {
                    id:2,
                    name : "Dialog",
                    component : Dialog_Sample,
                    description: "Dialogs inform users about a task and can contain critical information, require decisions, or involve multiple tasks."
                },
                {
                    id:3,
                    name : "Progress",
                    component : Progress_Sample,
                    description:"Progress indicators commonly known as spinners, express an unspecified wait time or display the length of a process."
                },
                {   
                    id:4,
                    name : "Skeleton",
                    component : Skeleton_Sample,
                    description: "Display a placeholder preview of your content before the data gets loaded to reduce load-time frustration."
                },
                {
                    id:5,
                    name : "Snackbar",
                    component : Snackbar_Sample,
                    description:"Snackbars provide brief notifications. The component is also known as a toast."
                }
            ]
        },
        {
            id:3,
            name : "Surfaces",
            components : [
                {
                    id:0,
                    name : "Accordion",
                    component : Accordion_Sample,
                    description: "The accordion component allows the user to show and hide sections of related content on a page."
                },
                {
                    id:1,
                    name : "App Bar",
                    component : AppBar_Sample,
                    description: "The App Bar displays information and actions relating to the current screen."
                },
                {
                    id:2,
                    name : "Card",
                    component : Card_Sample,
                    description: "Cards contain content and actions about a single subject."
                },
                {
                    id:3,
                    name : "Paper",
                    component : Paper_Sample,
                    description: "The background of an application resembles the flat, opaque texture of a sheet of paper, and an application's behavior mimics paper's ability to be re-sized, shuffled, and bound together in multiple sheets."
                }
            ]
        },
        {
            id:4,
            name : "Navigation",
            components : [
                {
                    id:0,
                    name : "Bottom Navigation",
                    component : BottomNavigation_Sample,
                    description: "The Bottom Navigation bar allows movement between primary destinations in an app."
                },
                {
                    id:1,
                    name : "Breadcrumbs",
                    component : Breadcrumbs_Sample,
                    description: "A breadcrumbs is a list of links that help visualize a page's location within a site's hierarchical structure, it allows navigation up to any of the ancestors."
                },
                {
                    id:2,
                    name : "Drawer",
                    component : Drawer_Sample,
                    description: "The navigation drawers (or 'sidebars') provide ergonomic access to destinations in a site or app functionality such as switching accounts."
                },
                {
                    id:3,
                    name : "Link",
                    component : Link_Sample,
                    description: "The Link component allows you to easily customize anchor elements with your theme colors and typography styles."
                },
                {
                    id:4,
                    name : "Menu",
                    component : Menu_Sample,
                    description: "Menus display a list of choices on temporary surfaces."
                },
                {
                    id:5,
                    name : "Pagination",
                    component : Pagination_Sample,
                    description: "The Pagination component enables the user to select a specific page from a range of pages."
                },
                {
                    id:6,
                    name : "Speed Dial",
                    component : SpeedDial_Sample,
                    description: "When pressed, a floating action button can display three to six related actions in the form of a Speed Dial."
                },
                {
                    id:7,
                    name : "Stepper",
                    component : Stepper_Sample,
                    description: "Steppers convey progress through numbered steps. It provides a wizard-like workflow."
                },
                {
                    id:8,
                    name : "Tabs",
                    component : Tabs_Sample,
                    description: "Tabs make it easy to explore and switch between different views."
                }
            ]
        },
        {
            id:5,
            name : "Utils",
            components : [
                {
                    id:0,
                    name : "Modal",
                    component : Modal_Sample,
                    description: "The modal component provides a solid foundation for creating dialogs, popovers, lightboxes, or whatever else."
                },
                {
                    id:1,
                    name : "Popover",
                    component : Popover_Sample,
                    description: "A Popover can be used to display some content on top of another."
                },
                {
                    id:2,
                    name : "Popper",
                    component : Popper_Sample,
                    description: "A Popper can be used to display some content on top of another. It's an alternative to react-popper."
                }
            ]
        },
        {
            id:5,
            name : "Layouts",
            components : [
                {
                    id:0,
                    name : "Layout Top",
                    component : LayoutTop_Sample,
                    description: "The main layout for your app pages. Has areas for: top menu, content and footer. In the content area, you can show breadcrumbs, a page title, actions and the page content."
                },
                {
                    id:1,
                    name : "Layout Blank",
                    component : LayoutBlank_Sample,
                    description: "A blank layout to use in pages like errors. Has only one area for content."
                },
                {
                    id:2,
                    name : "Layout Login",
                    component : LayoutLogin_Sample,
                    description: "The layout to use in login pages. Has areas for app name and description and also for a logo and the a form."
                }
            ]
        }
    ];


    const queryParameters = new URLSearchParams(window.location.search);
    const selectedComponentIndex = queryParameters.get("component");
    const selectedCategoryIndex = queryParameters.get("category");

    //SEARCH INPUT

    const [searchComponent, setSearchComponent] = React.useState('');

    const handleSearchComponent = event => {
        setSearchComponent(event.target.value);
    };



    if(selectedComponentIndex != null && selectedCategoryIndex != null){

        let SampleComponent = componentList[selectedCategoryIndex].components[selectedComponentIndex].component;

        return (
            <LayoutTopWrapperLSG 
                title={componentList[selectedCategoryIndex].components[selectedComponentIndex].name}  
                breadcrumbs={[
                    <Link to="/">Home</Link>,
                    <Link to="/components/">Components</Link>,
                    <span>{componentList[selectedCategoryIndex].components[selectedComponentIndex].name}</span>
                ]}>
                <p className="margin-bottom-m">{componentList[selectedCategoryIndex].components[selectedComponentIndex].description}</p>
                <SampleComponent />
            </LayoutTopWrapperLSG>
        )

    }else{
        return (

            <LayoutTopWrapperLSG title="Components" breadcrumbs={[
                <Link to="/">Home</Link>,
                <span>Components</span>
              ]}>
                <div className="margin-bottom-l font-size-h6">All styled MUI components ready to be copied to your project code.</div>

                <TextField
                        className="margin-bottom-xxl"
                        value ={searchComponent}
                        onChange={handleSearchComponent}
                        InputProps={{startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>), 
                }} 
                placeholder="Search by name"
                />
                 
                <div className="components__wrapper">
            {
                componentList.map((category, categoryIndex) => {const filteredComponents = category.components.filter((component) =>
                    component.name.toLowerCase().includes(searchComponent.toLowerCase())
                  );
                    return (
                       
                            <div className="components__category" key={categoryIndex}>
                            {filteredComponents.length > 0 && <h3>{category.name}</h3>}
                            <Divider className="background-secondary margin-y-s"></Divider>
                            {filteredComponents.length > 0 && (
                                <div className="components__list">
                                    {filteredComponents.map((component, componentIndex) => (
                                    <a
                                        className="components__list-item"
                                        key={componentIndex}
                                        href={`/components?category=${categoryIndex}&component=${component.id}`}
                                    >
                                        {component.name}
                                    </a>
                                    ))}
                                </div>
                            )}
                            </div>
                      
                );
                })}
                </div>
            </LayoutTopWrapperLSG>
        )
    }

}

