import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import PreviewComponent from '../app-components/PreviewComponent';

const steps = ['Label 1', 'Label 2', 'Label 3'];

export default function Stepper_Sample() {

  const sampleStepperHorDefault = "<Stepper alternativeLabel>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 1</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 2</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 3</StepLabel>" +
    "\n </Step>" +
    "\n</Stepper>";

  const sampleStepperHorSmall = "<Stepper className='small' alternativeLabel>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 1</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 2</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 3</StepLabel>" +
    "\n </Step>" +
    "\n</Stepper>";

  const sampleStepperVerDefault = "<Stepper orientation='vertical'>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 1</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 2</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 3</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 4</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 5</StepLabel>" +
    "\n </Step>" +
    "\n</Stepper>";

  const sampleStepperVerSmall = "<Stepper className='small' orientation='vertical'>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 1</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 2</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 3</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 4</StepLabel>" +
    "\n </Step>" +
    "\n <Step>" +
    "\n  <StepLabel>Label 5</StepLabel>" +
    "\n </Step>" +
    "\n</Stepper>";
   
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>

    <PreviewComponent title="Horizontal Default" description="" code={sampleStepperHorDefault}>
        <Box>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
                stepProps.completed = false;
            }
            return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
            );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
                <div className='margin-top-base font-size-base'>
                    All steps completed - you&apos;re finished
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='margin-top-base font-size-base'>Step {activeStep + 1}</div>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleSkip}>Skip</Button>
                  <Button onClick={handleNext} className='margin-left-s'>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
    </PreviewComponent>

    <PreviewComponent title="Horizontal Small" description="" code={sampleStepperHorSmall}>
        <Box>
          <Stepper className='small' activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
            stepProps.completed = false;
            }
            return (
            <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
            );
          })}
          </Stepper>
          {activeStep === steps.length ? (
          <React.Fragment>
            <div className='margin-top-base font-size-base'>
                All steps completed - you&apos;re finished
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
          ) : (
          <React.Fragment>
            <div className='margin-top-base font-size-base'>Step {activeStep + 1}</div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleSkip}>Skip</Button>
                <Button onClick={handleNext} className='margin-left-s'>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
            </Box>
          </React.Fragment>
          )}
        </Box>
    </PreviewComponent>

    <PreviewComponent title="Vertical Default" description="" code={sampleStepperVerDefault}> 
        <Box>
            <Stepper orientation="vertical" activeStep={2}>
                <Step>
                    <StepLabel>Label 1</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 2</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 3</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 4</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 5</StepLabel>
                </Step>
            </Stepper>
        </Box>
    </PreviewComponent>

    <PreviewComponent title="Vertical Small" description="" code={sampleStepperVerSmall}> 
        <Box>
            <Stepper className='small' orientation="vertical" activeStep={2}>
                <Step>
                    <StepLabel>Label 1</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 2</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 3</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 4</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Label 5</StepLabel>
                </Step>
            </Stepper>
        </Box>
      </PreviewComponent>
    </div>
  );
}
