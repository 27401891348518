import CopyToClipboard from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function CodeBlock({ code }) {
  return (
    <div className="code-preview">
      <SyntaxHighlighter
        code={code}
        style={monokaiSublime}
        language="xml"
        showLineNumbers="true"
      />
      <CopyToClipboard text={code}>
        <button className="code-preview-button">Copy Code</button>
      </CopyToClipboard>
    </div>
  );
}
