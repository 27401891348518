import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import PreviewComponent from "../app-components/PreviewComponent";

export default function Tooltip_Sample() {
  const sampleLetters =
    "<Tooltip title='Tooltip' placement='top-start'>" +
    "\n<Tooltip title='Tooltip' placement='top'>" +
    "\n<Tooltip title='Tooltip' placement='top-end'>" +
    "\n<Tooltip title='Tooltip' placement='left'>" +
    "\n<Tooltip title='Tooltip' placement='right'>" +
    "\n<Tooltip title='Tooltip' placement='bottom-start'>" +
    "\n<Tooltip title='Tooltip' placement='bottom'>" +
    "\n<Tooltip title='Tooltip' placement='bottom-end'>";

  return (
    <PreviewComponent title="" description="" code={sampleLetters}>
      <Grid container justifyContent="center">
        <Grid item>
          <Tooltip title="Tooltip" placement="top-start">
            <Button>top-start</Button>
          </Tooltip>
          <Tooltip title="Tooltip" placement="top">
            <Button className="margin-x-base">top</Button>
          </Tooltip>
          <Tooltip title="Tooltip" placement="top-end">
            <Button>top-end</Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Tooltip title="Tooltip" placement="left">
            <Button>left</Button>
          </Tooltip>
        </Grid>
        <Grid item container xs={6} alignItems="flex-end" direction="column">
          <Grid item>
            <Tooltip title="Tooltip" placement="right">
              <Button>right</Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Tooltip title="Tooltip" placement="bottom-start">
            <Button>bottom-start</Button>
          </Tooltip>
          <Tooltip title="Tooltip" placement="bottom">
            <Button className="margin-x-base">bottom</Button>
          </Tooltip>
          <Tooltip title="Tooltip" placement="bottom-end">
            <Button>bottom-end</Button>
          </Tooltip>
        </Grid>
      </Grid>
    </PreviewComponent>
  );
}
