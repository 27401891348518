import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PreviewComponent from '../app-components/PreviewComponent';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function ButtonAppBar() {

  const sample_appBar="<AppBar className='static'>" +
                      "\n  <Toolbar>" +
                      "\n    <IconButton>" +
                      "\n      <MenuIcon />" +
                      "\n    </IconButton>" +
                      "\n    <span className='padding-left-base font-size-base flex1'>News</span>" +
                      "\n    <Button>Login</Button>" +
                      "\n  </Toolbar>" +
                      "\n</AppBar>"

  const sample_searchAppBar="<AppBar className='static'>" +
                      "\n  <Toolbar>" +
                      "\n    <IconButton >" +
                      "\n    <MenuIcon />" +
                      "\n    </IconButton>" +
                      "\n    <span className='padding-left-base font-size-base flex1'>News</span>" +
                      "\n    <Search>" +
                      "\n      <SearchIconWrapper>" +
                      "\n        <SearchIcon />" +
                      "\n      </SearchIconWrapper>" +
                      "\n      <StyledInputBase" +
                      "\n        placeholder='Search…'" +
                      "\n      />" +
                      "\n    </Search>" +
                      "\n  </Toolbar>" +
                      "\n</AppBar>"

  return (
    <div>  
      <PreviewComponent title="Basic" description="" code={sample_appBar}>
        <Box>
          <AppBar className='static'>
            <Toolbar>
              <IconButton>
                <MenuIcon />
              </IconButton>
              <span className='padding-left-base font-size-base flex1'>News</span>
              <Button>Login</Button>
            </Toolbar>
          </AppBar>      
        </Box>
      </PreviewComponent>

      <PreviewComponent title="With Search Field" description="" code={sample_searchAppBar}>
        <Box>
          <AppBar className='static'>
            <Toolbar>
              <IconButton >
                <MenuIcon />
              </IconButton>
              <span className='padding-left-base font-size-base flex1'>News</span>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                />
              </Search>
            </Toolbar>
          </AppBar>
        </Box>
      </PreviewComponent>
    </div>
  );
}