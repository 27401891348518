import { Link } from "react-router-dom";
import LayoutTopWrapperLSG from "../app-components/LayoutTopWrapperLSG";
import PreviewScreenTemplate from "../app-components/PreviewScreenTemplate";

export default function ScreenSamples(){

    const screenTemplatesList = [
        {
            title : "Loading",
            description : "It is used to display tables with basic lists, for status queries, or for a rapid generic search.",
            imageurl : "/images/LoadingScreen.png",
            url : "/loading-screen"
        },
        {
            title : "Simple Form",
            description : "Used to answer questionnaires, exemplifying various sorts of questions, with more simple parts and subsections, allowing the user to know where he is at the moment and how many steps left to finish.",
            imageurl : "/images/SimpleFormScreen.png",
            url : "/simple-form"
        },
        {
            title : "Simple List",
            description : "It is used to display tables with basic lists, for status queries, or for a rapid generic search.",
            imageurl : "/images/SimpleListScreen.png",
            url : "/simple-list"
        },
        {
            title : "Error",
            description : "It is used to display an error message if something has not been done the right way.",
            imageurl : "/images/ErrorScreen.png",
            url : "/error"
        },
        {
            title : "Login",
            description : "Used in conjunction with a basic form to allow the user to access the program if he is enrolled in it, or to register.",
            imageurl : "/images/LoginScreen.png",
            url : "/login"
        }
    ];

    return (

        <LayoutTopWrapperLSG title="Screen Templates" halfSize={false} breadcrumbs={[
            <Link to="/">Home</Link>,
            <span>Screen Templates</span>
          ]}>

            <div className="margin-bottom-l font-size-h6">Start building apps with pre-assembled screens following UX guidelines and using your own data.</div>
            <div className="screen-template-list">
                {                    
                    screenTemplatesList.map((screen, screenIndex) => 
                        <PreviewScreenTemplate 
                            title={screen.title} 
                            description={screen.description} 
                            url={screen.url} 
                            imageurl={screen.imageurl} 
                            key={screenIndex}
                        />
                    )
                }
            </div>
        </LayoutTopWrapperLSG>  
    )
}