import * as React from 'react';
import Switch from '@mui/material/Switch';
import PreviewComponent from '../app-components/PreviewComponent';
import { Stack } from '@mui/material';

export default function BasicSwitch() {

  const sampleCode_default= "<Switch defaultChecked />" + 
                          "\n<Switch />";

  const sampleCode_disabled = "<Switch disabled defaultChecked/>" +
                            "\n<Switch disabled/>";

  return (
    <div>
      <PreviewComponent title="Default" description="" code={sampleCode_default}>
        <Stack direction='column' spacing={2}>   
            <Switch defaultChecked />
            <Switch />
        </Stack>
      </PreviewComponent>

      <PreviewComponent title="Disabled" description="" code={sampleCode_disabled}>
        <Stack direction='column' spacing={2}>
            <Switch disabled defaultChecked />
            <Switch disabled />
        </Stack>
      </PreviewComponent>
    </div>
  );
}


