import * as React from 'react';
import PreviewComponent from '../../app-components/PreviewComponent';

export default function LayoutBlank_Sample() {

  
  const sampleCode_layoutBlank = 
      '<div className={"layout-blank"} key="layout-blank">' +
      '\n\t{props.children}' +
      '\n</div>';
  
  return (
    <div>
      <PreviewComponent title="" description="" code={sampleCode_layoutBlank}>
        This layout has no configurable parameters.
      </PreviewComponent>
    </div>
  );
}